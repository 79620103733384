import React from 'react';
// import ReactDOM from 'react-dom';
// import store from './store'
// import { Provider } from 'react-redux';
import './sass/style.scss'

// import * as serviceWorker from './serviceWorker';

const isAppElementInPage = (elementId) => {
  return !!document.getElementById(elementId);
}

const searchBlockAppElementId = 'search-box-app';
if (isAppElementInPage(searchBlockAppElementId)) {
  import('./apps/search-block').then(module => {
    module.default(searchBlockAppElementId);
  });
}

const searchResultsAppElementId = 'search-app';
if (isAppElementInPage(searchResultsAppElementId)) {
  import('./apps/search-results').then((module) => {
    module.default(searchResultsAppElementId);
  });
}

const cookieLawAppElementId = 'wrapper-app-cookiebot-dialog';
if (isAppElementInPage(cookieLawAppElementId)) {
  import('./apps/cookiebot-dialog').then((module) => {
    module.default(cookieLawAppElementId);
  });
}

const loginAppElementId = 'login-form-block';
if (isAppElementInPage(loginAppElementId)) {
  import('./apps/login').then((module) => {
    module.default(loginAppElementId);
  });
}

const racTypPageElementId = 'rac-typ-page';
if (isAppElementInPage(racTypPageElementId)) {
  import('./apps/thank-you-page').then((module) => {
    module.default(racTypPageElementId);
  });
}

import('./apps/scripts').then((module) => {
  module.default();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
